<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-files"> </i> 档案管理 | 档案统计</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <el-select v-model="grid.sea.AID" @change="filter" placeholder="请选择项目">
                                <el-option v-for="item in archiveList" :key="item.Key" :value="item.Key"
                                    :label="item.Value" />
                            </el-select>
                            <el-date-picker v-model="grid.sea.SDATE" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择开始日期">
                            </el-date-picker>
                            <el-date-picker v-model="grid.sea.EDATE" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择结束日期">
                            </el-date-picker>
                        </div>
                        <!-- <wgrid :data="grid.list" v-loading="grid.loading">
                            <el-table-column prop="TeacherName" label="姓名" width="200" />
                            <el-table-column prop="FileCount" label="上传文件数量" width="300" />
                        </wgrid> -->
                        <el-table :data="grid.list" stripe border :cell-style="{padding:'0px'}"
                            :header-cell-style="{borderBottom:'solid 1px #ccc',backgroundColor:'#f5f5f5',textAlign:'center',padding:'5px 0px'}">
                            <el-table-column prop="TeacherName" label="姓名" width="120" align="center">
                                <template slot-scope="scope">
                                    <b v-if="scope.row.TeacherId==0">{{scope.row.TeacherName}}</b>
                                    <span v-else>{{scope.row.TeacherName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="FileCount" label="上传文件数量" width="150" align="center">
                                <template slot-scope="scope">
                                    <b v-if="scope.row.TeacherId==0">文件总量：{{scope.row.FileCount}}</b>
                                    <span v-else>{{scope.row.FileCount}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column />
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

    // import Wgrid from "@/components/wgrid";
    export default {
        name: "index",
        components: {},
        data() {
            return {
                grid: {
                    list: [],
                    loading: false,
                    sea: {
                        AID: 0
                    }
                },
                archiveList: []
            }
        },
        created() {
            this.getArchives();
            this.getList();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
            },

            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/ContentApi/GetFileTj",
                    data: this.grid.sea,
                    completed: function (its) {
                        self.grid.list = its.DATA;
                        self.grid.loading = false;
                    }
                })
            },
            getArchives() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/ArchiveApi/GetArchives",
                    completed: function (its) {
                        console.log(its.DATA)
                        self.archiveList = its.DATA;
                        self.archiveList.unshift({ Key: 0, Value: "全部" })
                    }
                })
            },
        }
    }
</script>